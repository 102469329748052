import { Box } from '@chakra-ui/react';


export const UserPrompt = ({ prompt, bubbleWidth }) => {
    return (
        <Box display="flex" justifyContent="flex-end">
            <Box
                name="user-prompt"
                position="relative"
                display="flex"
                flexDirection="row"
                alignItems='center'
                justifyContent="flex-end"
                gap="9px"
                padding="9px 0px"
                marginRight="4px"
                className="message"
                width={bubbleWidth}
            >
                <Box
                    borderRadius="12px 0px 12px 12px"
                    background="rgba(255, 255, 255, 0.03)"
                    display="flex"
                    padding="18px"
                    justifyContent="flex-end"
                    alignItems="center"
                    flex="1 0 0"
                    width="301px"
                >
                    {prompt}
                </Box>
            </Box>
        </Box>
    )
}

import React from 'react'
import { Image } from '@chakra-ui/react'
import Typing from './Typing.jsx'

// Three dots animation for both user and widget.
export const TypingWithIcon = ({ isBot, withIcon = false, show, avatar, width }) => {
	if (!show) return
	return (
		<div
			className={`animation ${isBot? 'mb-0' : 'mb-[9px] flex justify-end'}`}>
			<div
				style={{
					minWidth: '301px',
					width: width,
					display: 'flex',
					justifyContent: isBot ? 'flex-start' : 'flex-end',
					padding: '18px',
					borderRadius: isBot ? '0px 12px 12px 12px' : '12px 0px 12px 12px',
					background: isBot ? 'rgba(255, 255, 255, 0.03)' : 'rgba(255, 255, 255, 0.03)',
				}}
			>
				<Typing />
			</div>
			{withIcon ? (
				isBot ? (
					<Image alt="Avatar System" src={avatar} height="38px" width="38px"></Image>
				) : (
					<Image alt="Avatar You" src="/icon/avatar-you.png" height="38px" width="38px"></Image>
				)
			) : (
				''
			)}
		</div>
	)
}

export default TypingWithIcon

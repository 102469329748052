const SKELETON_BG_COLOR = '#716b6b12';
const SKELETON_ANIMATION = 'pulse 3s infinite'

export const BubbleSkeleton = ({bubbleWidth}) => {
    const userBubbleStyle = {
        animation: SKELETON_ANIMATION,
        backgroundColor: SKELETON_BG_COLOR,
        borderRadius: '0.375rem',
        width: `${bubbleWidth}px`,
        height: '4rem',
        marginBottom: '1rem',
        marginLeft: '50px'
    };

    const botBubbleStyle = {
        animation: SKELETON_ANIMATION,
        backgroundColor: SKELETON_BG_COLOR,
        borderRadius: '0.375rem',
        width: `${bubbleWidth}px`,
        height: '8rem',
        marginBottom: '1rem',
    };

    return (
        <div>
            <div style={botBubbleStyle}></div>
            <div style={userBubbleStyle}></div>
            <div style={botBubbleStyle}></div>
            <div style={userBubbleStyle}></div>
        </div>
    );
};
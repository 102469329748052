import { Box, ChakraProvider, Image } from '@chakra-ui/react';
import { Worker } from '@react-pdf-viewer/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { ChatFooter } from './components/ChatFooter.jsx';
import { ChatOverlayModal } from './components/ChatOverlayModal.jsx';
import ContactForm from './components/ContactForm/ContactForm.jsx';
import HistoryForm from './components/ContactForm/HistoryForm.jsx';
import { Convo } from './components/Convo/Convo.jsx';
import Documents from './components/Documents/Documents.jsx';
import { FilterPanel } from './components/Filter/FilterPanel.jsx';
import { WidgetInput } from './components/WidgetInput.jsx';
import { WidgetSearchResults } from './components/WidgetSearchResults.jsx';
import { parentService } from './service/index.js';
import { widgetSettings } from './settings';
import { Context } from './store/context.js';
import { useStore } from './store/store.js';
import theme from './theme.js';

export const App = () => {
	const {
		searchResults,
		refinedSynthFilters,
		widgetSession,
		setWidgetSession,
		loadHistory,
		widgetLog,
		setPrompt,
		contactSupportForm,
		setContactSupportForm,
		sendHistoryForm,
		setSendHistoryForm,
		isShowFilterPanel,
		setIsShowFilterPanel,
		setIsPrompting,
		requestSession,
		setBubbleWidth,
		setIsTryingToLoadHistory,
	} = useStore(
		useShallow((state) => ({
			searchResults: state.searchResults,
			refinedSynthFilters: state.refinedSynthFilters,
			widgetSession: state.widgetSession,
			setWidgetSession: state.setWidgetSession,
			loadHistory: state.loadHistory,
			widgetLog: state.widgetLog,
			setPrompt: state.setPrompt,
			contactSupportForm: state.contactSupportForm,
			setContactSupportForm: state.setContactSupportForm,
			sendHistoryForm: state.sendHistoryForm,
			setSendHistoryForm: state.setSendHistoryForm,
			isShowFilterPanel: state.isShowFilterPanel,
			setIsShowFilterPanel: state.setIsShowFilterPanel,
			setIsPrompting: state.setIsPrompting,
			requestSession: state.requestSession,
			setBubbleWidth: state.setBubbleWidth,
			setIsTryingToLoadHistory: state.setIsTryingToLoadHistory,
		}))
	);
	const inputRef = useRef(null)
	const submitRef = useRef(null)
	const latestMessageRef = useRef(null)

	const [isShowSRPanel, setIsShowSRPanel] = useState(false)
	// Using a reference to ensure the access to the most current state of the variable
	// Direct usage of isShowSRPanel might result in a stale state, causing improper visibility of the panel
	const isShowSRPanelRef = useRef(isShowSRPanel)
	const [isShowDocPanel, setShowDocPanel] = useState(false)
	// Using ref here for the same reason as above
	const isShowDocPanelRef = useRef(isShowDocPanel)

	const [rightPanelWidth, setRightPanelWidth] = useState(widgetSettings.DOC_PANEL_DEFAULT_WIDTH)
	const [chosenDocUrl, setChosenDocUrl] = useState(null)
	const [pageIndex, setPageIndex] = useState(0)

	const chatPanelRef = useRef(null)
	const chatPanelCurrentWidth = chatPanelRef.current ? chatPanelRef.current.offsetWidth : 0

	useEffect(() => {
		isShowSRPanelRef.current = isShowSRPanel;
	}, [isShowSRPanel]);


	useEffect(() => {
		isShowDocPanelRef.current = isShowDocPanel;
	}, [isShowDocPanel]);


	const handleOpenSR = useCallback(() => {
		if (isShowSRPanelRef.current === false) {
			handleCloseDoc() // Open one, close another
			setIsShowSRPanel(true)
			window.parent.postMessage({
				action: 'cv-iframe-resize',
				width: chatPanelRef.current.offsetWidth + widgetSettings.SR_PANEL_DEFAULT_WIDTH,
				rightPanelWidth: widgetSettings.SR_PANEL_DEFAULT_WIDTH,
			}, '*',)
		}
	}, [])

	const handleCloseSR = useCallback(() => {
		if (isShowSRPanelRef.current === true) {
			widgetLog('search_close')
			window.parent.postMessage(
				{
					action: 'cv-iframe-resize', width: chatPanelRef.current.offsetWidth,
					rightPanelWidth: 0,

				},
				'*',
			)
			setIsShowSRPanel(false)
		}
	}, [])

	const handleOpenDoc = useCallback((fileUrl, chosenIndex) => {
		if (fileUrl) {
			widgetLog('attribution_click')
			handleCloseSR() // Open one, close another 	
			setTimeout(() => {
				setPageIndex(chosenIndex)
				setChosenDocUrl(fileUrl)
				// Only check this condition after updating the page index because the doc panel might open already
				// Otherwise, it cannot update the page			
				if (isShowDocPanelRef.current === false) {
					setShowDocPanel(true)
					window.parent.postMessage({
						action: 'cv-iframe-resize',
						width: chatPanelRef.current.offsetWidth + rightPanelWidth,
						rightPanelWidth: widgetSettings.DOC_PANEL_DEFAULT_WIDTH,
					}, '*',)
				}
			}, 500) // 0.5s timeout for the open animaiton to finish
		}
	}, [])

	const handleCloseDoc = useCallback(() => {
		if (isShowDocPanelRef.current === true) {

			window.parent.postMessage({
				action: 'cv-iframe-resize',
				width: chatPanelRef.current.offsetWidth,
				rightPanelWidth: 0,
			}, '*')
			setShowDocPanel(false)
			setChosenDocUrl(null) // Resetting the doc is necessary for the document component to unmount & load properly later.
			setPageIndex(0)
			widgetLog('pdf_close')
		}
	}, [])

	// ------------------------- LISTENING TO PARENT'S MESSAGES ------------------------- //
	useEffect(() => {
		// Listening to parent window
		window.addEventListener('message', async (event) => {
			if (event.data.action === 'cv-session-response') {
				if (event.data.shouldLoadHistory) { // only load history the first time this action happens
					// Retry if failed to account for BE's delay when switching page
					async function loadHistoryWithRetry(id, maxRetries = 20, interval = 200) {
						let retries = 0;
						let history = [];

						while (retries < maxRetries) {
							try {
								history = await loadHistory(id);
								break;
							} catch (error) {
								retries++;
								if (retries >= maxRetries) {
									setConvos([])
									throw new Error(`Failed to load history after ${maxRetries} attempts: ${error.message}`);
								}
								await new Promise(resolve => setTimeout(resolve, interval));
							}
						}

						return history;
					}
					setIsTryingToLoadHistory(true)
					await loadHistoryWithRetry(event.data.id)
					setIsTryingToLoadHistory(false)
				}

				// Setting up the stream to receive session information from the parent.

				setWidgetSession(event.data)
				const rightPanelMaxWidth = Math.min(
					widgetSettings.DOC_PANEL_DEFAULT_WIDTH,
					event.data.screenSize.width - widgetSettings.CHAT_PANEL_MIN_WIDTH,
				)
				setRightPanelWidth(rightPanelMaxWidth)
			}

			if (event.data.action === 'cv-open-widget') {
				if (widgetSession.hasParent !== true) {
					inputRef.current && inputRef.current.focus()
				}
			}

			if (event.data.action === 'cv-close-widget') {
				handleCloseDoc()
				handleCloseSR()
				setIsShowFilterPanel(false)
			}
		})
		// Notify the merchant store that the page is ready by requesting the session
		requestSession()
	}, [])

	// Resize the chat panel based on the container resize event 
	window.addEventListener('message', (event) => {
		if (event.data.type === 'cv-container-resize') {
			const rightPanelWidth = isShowSRPanel
				? widgetSettings.SR_PANEL_DEFAULT_WIDTH
				: isShowDocPanel
					? widgetSettings.DOC_PANEL_DEFAULT_WIDTH
					: 0
			const newChatPanelWidth = event.data.width - rightPanelWidth
			chatPanelRef.current && (chatPanelRef.current.style.width = `${newChatPanelWidth}px`)
			const newBubbleWidth = newChatPanelWidth - widgetSettings.CHAT_PANEL_BUBBLE_DIFF
			setBubbleWidth(newBubbleWidth)
		}
	})

	const reset = useCallback(() => {
		setPrompt('')
		setIsPrompting(false)
		handleCloseDoc()
		widgetLog('clear_convo')
		parentService.resetSession()

		let parentUrl = new URL(widgetSession.url);
		// Remove sessionId from URL because sessionId is used to restore old session
		// and here we want to create a new session.
		parentUrl.searchParams.delete('sessionId');
		let reloadUrl = parentUrl.toString()
		window.parent.location.href = reloadUrl.toString();
	}, [widgetSession])

	const contextValue = useMemo(
		() => ({
			reset,
			handleOpenDoc,
			handleOpenSR,
			handleCloseSR,
			isShowSRPanel,
			setIsShowSRPanel,
			chatPanelCurrentWidth
		}),
		[
			reset,
			handleOpenDoc,
			handleOpenSR,
			handleCloseSR,
			isShowSRPanel,
			setIsShowSRPanel,
			chatPanelCurrentWidth,
		],
	)

	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
			<ChakraProvider theme={theme}>
				<Context.Provider value={contextValue}>
					<div className="main-app">
						{/* Main Chat Section */}
						<section
							name="chatPanel"
							className="overflow-hidden h-screen bg-black relative flex flex-col"
							ref={chatPanelRef}
							style={{
								minWidth: widgetSettings.CHAT_PANEL_MIN_WIDTH,
							}}
						>
							<WidgetInput
								inputRef={inputRef}
								submitRef={submitRef}
								isEnterpriseQna={true}
								isShowSRPanel={isShowSRPanel}
								isShowDocPanel={isShowDocPanel}
							/>

							{/* Chat */}
							<div className="flex flex-col min-h-[calc(100vh-105px)] items-start p-[0_21px] relative text-white bg-[#272727] flex-grow">
								<div className="chat__wrapper w-full flex-grow">
									<div className="chat__main">
										<Convo
											latestMessageRef={latestMessageRef}
											inputRef={inputRef}
											chatPanelRef={chatPanelRef}
											avatar={widgetSession.appearanceConfig?.avatar}
										/>
									</div>
								</div>

								{/* Chat Footer */}
								{widgetSession.appearanceConfig.isShowLogo && <ChatFooter domain={widgetSession.domain} />}
							</div>

							{/* Contact Support Form */}
							{contactSupportForm && (
								<ChatOverlayModal
									title={`${widgetSession.appearanceConfig?.merchantName} customer support`}
									onClose={() => setContactSupportForm(false)}
								>
									<ContactForm />
								</ChatOverlayModal>
							)}

							{/* History Form */}
							{sendHistoryForm && (
								<ChatOverlayModal
									title="Share your conversation history"
									onClose={() => setSendHistoryForm(false)}
								>
									<HistoryForm />
								</ChatOverlayModal>
							)}

							{refinedSynthFilters.length > 0 && isShowFilterPanel && (
								<ChatOverlayModal
									title="Search Filters"
									onClose={() => setIsShowFilterPanel(false)}
								>
									<FilterPanel />
								</ChatOverlayModal>
							)}
						</section>



						{/* Search Result Panel */}
						{widgetSession.isInstantSearchEnabled === false && Array.isArray(searchResults) && searchResults.length > 0 && isShowSRPanel &&
							<WidgetSearchResults />
						}



						{/* Source Docs Panel */}
						{chosenDocUrl && (
							<Box width={isShowDocPanel ? rightPanelWidth : 0} overflow="hidden" height="100vh" name="rightPanel" bg="bg.darkGrey" padding="0 18px"
								transition="width 0.5s cubic-bezier(0.820, 0.085, 0.395, 0.895)" >
								<>
									<Box display="flex" justifyContent={'flex-end'} padding={'1.1rem 1rem 0'}>
										<Image src="/icon/close-dark.png" alt="Close PDF Button" cursor="pointer" onClick={handleCloseDoc} />
									</Box>

									<Documents
										docUrl={chosenDocUrl}
										pageIndex={pageIndex}
										setPageIndex={setPageIndex}
										isEnterpriseQna={true} />
								</>
							</Box>
						)}
					</div>
				</Context.Provider >
			</ChakraProvider >
		</Worker >
	)
}


import React, { useState } from 'react'
import { ThumbsUp, ThumbsDown } from 'lucide-react'
import { ThumbModal } from './ThumbModal.jsx'
import axios from 'axios'
import { useApiConfig } from '../../../hooks/useApiConfig.js'
import { sentryUtils } from '../../../lib/index.js'

export const ThumbBox = ({ isPositive, thumbStatus, setThumbStatus, convo }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isThumbModalOpen, setIsThumbModalOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const Thumb = isPositive ? ThumbsUp : ThumbsDown
    const isSelected = (isPositive && thumbStatus === 'positive') || (!isPositive && thumbStatus === 'negative') ? true : false
    const onHoverStyle = {
        background: '#413a4b',
        cursor: 'pointer'
    }

    const handleThumbClick = () => {
        if (!thumbStatus) {
            setIsThumbModalOpen(true)
        }
    }

    const closeThumbModal = () => {
        setIsThumbModalOpen(false)
    }

    const handleSubmit = async (feedback) => {
        const { apiBaseUrl } = useApiConfig()
        const endpoint = `${apiBaseUrl}/feedback`
        try {
            setIsSubmitting(true)
            const data = {
                "request_id": convo.requestId,
                "user_message": convo.userPrompt || 'search the web',
                "system_message": convo.botResponse,
                "rating": isPositive,
                "comment": feedback
            };
            await axios.post(endpoint, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setThumbStatus(isPositive ? 'positive' : 'negative')
            setIsThumbModalOpen(false)

        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        } finally {
            setIsSubmitting(false)
        }
    }


    return (
        <div className="relative">
            <div className={`ml-2 w-[32px] h-[32px] flex justify-center items-center rounded-full`}
                onMouseEnter={() => !thumbStatus && setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={isHovered ? onHoverStyle : {}}
                onClick={handleThumbClick}
            >
                <Thumb size={14} color={isSelected ? '#fff' : '#838383'} />

            </div>
            {isThumbModalOpen && <ThumbModal handleModalClose={closeThumbModal} handleSubmit={handleSubmit} isSubmitting={isSubmitting} />}
            {isHovered && (
                <>
                    <div className="absolute right-10 top-0 whitespace-nowrap py-1 px-3 text-sm bg-white text-black rounded">
                        {isPositive ? 'Helpful' : 'Not helpful'}
                    </div>
                    <div className="tooltip-arrow absolute right-[33px] top-[21%]">
                        <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.62009e-07 2.41421L2.87892e-08 13.5858C1.81653e-08 14.4767 1.07714 14.9229 1.70711 14.2929L7.29289 8.70711C7.68342 8.31658 7.68342 7.68342 7.29289 7.29289L1.70711 1.70711C1.07714 1.07714 1.72633e-07 1.52331 1.62009e-07 2.41421Z" fill="#FAF8F8" />
                        </svg>

                    </div>
                </>
            )}
        </div>
    )
}
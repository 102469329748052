import React, { useContext } from 'react';
import { useStore } from '../../store/store.js';
import { useShallow } from 'zustand/react/shallow';
import { convoSettings } from '../../settings';
import { Context } from '../../store/context.js'

const MessageViewQuery = ({ convo }) => {
    const {
        widgetSession,
        setSearchResults,
        setLastSearchPrompt,
        setSynthFilters,
        resetSelectedFilters,
    } = useStore(useShallow(state => ({
        widgetSession: state.widgetSession,
        setSearchResults: state.setSearchResults,
        setLastSearchPrompt: state.setLastSearchPrompt,
        setSynthFilters: state.setSynthFilters,
        resetSelectedFilters: state.resetSelectedFilters,
    })));

    const { handleOpenSR, handleCloseSR } = useContext(Context)

    const hasSearchResults = Array.isArray(convo.searchResults);
    const hasSynthFilters = Array.isArray(convo.synthFilters) && convo.synthFilters.length > 0;

    let queryMessage = convoSettings.DEFAULT_QUERY_MESSAGE;

    if (hasSearchResults && hasSynthFilters) {
        const queryFilter = convo.synthFilters.find(([key]) => key.toLowerCase() === 'generated search query');
        if (queryFilter && queryFilter[1]) queryMessage = `View ${queryFilter[1].toLowerCase()}`;
    }

    const handleMessageQueryClick = async () => {
        if (convo.isWelcomeMessage) return;
        if (convo.requestId && hasSearchResults) {
            setSearchResults(convo.searchResults);
            resetSelectedFilters();
            setLastSearchPrompt(convo.userPrompt);

            if (hasSynthFilters) {
                setSynthFilters(convo.synthFilters);
            }

            if (widgetSession.isInstantSearchEnabled) {
                return;
            }
            if (Array.isArray(convo.searchResults) && convo.searchResults.length > 0) {
                handleOpenSR();
            } else {
                handleCloseSR();
            }
        } else {
            window.parent.postMessage({ action: 'cv-request-parent-redirection', url: convo.pageUrl }, '*');
        }
    };

    return (
        <div
            className="text-[13px] font-normal leading-[22px] cursor-pointer hover:opacity-80 active:opacity-60"
            onClick={handleMessageQueryClick}
            style={{ color: widgetSession.appearanceConfig.linkColor }}
        >
            {hasSearchResults ? queryMessage : !convo.isWelcomeMessage ? 'View Page' : null}
        </div>
    );
};

export default MessageViewQuery; 
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { BotResponse } from './BotResponse.jsx'
import { Box, useDisclosure, } from '@chakra-ui/react'
import { useShallow } from 'zustand/react/shallow'
import { convoSettings } from '../../settings'
import { Context } from '../../store/context.js'
import { useStore } from '../../store/store.js'
import { ConvoClearModal } from './ConvoClearModal.jsx'
import { ConvoHeader } from './ConvoHeader.jsx'
import { UserPrompt } from '../UserPrompt.jsx'
import { BotTyping } from '../Animation/Typing/BotTyping.jsx'
import { UserTyping } from '../Animation/Typing/UserTyping.jsx'
import { hoverScrollbarStyle } from '../../Common/commonStyles.js'
import { BubbleSkeleton } from './BubbleSkeleton.jsx'

export const Convo = ({
	avatar,
	inputRef,
	latestMessageRef,
}) => {
	const {
		isTryingToLoadHistory,
		isEntLoading,
		isPrompting,
		convos,
		appendConvos,
		widgetSession,
		agentStatus,
		setAgentStatus,
		updateConvo,
		setEntLoading,
		bubbleWidth
	} = useStore(useShallow(state => ({
		isTryingToLoadHistory: state.isTryingToLoadHistory,
		isEntLoading: state.isEntLoading,
		isPrompting: state.isPrompting,
		convos: state.convos,
		appendConvos: state.appendConvos,
		widgetSession: state.widgetSession,
		agentStatus: state.agentStatus,
		setAgentStatus: state.setAgentStatus,
		updateConvo: state.updateConvo,
		setEntLoading: state.setEntLoading,
		bubbleWidth: state.bubbleWidth
	})))
	const { reset } = useContext(Context)

	useEffect(() => {
		// Only execute once and skip when the product name is not determined yet (during early page load)		
		if ((Array.isArray(convos) && convos.length > 0) || widgetSession.productName === undefined) return;		
		appendConvos({
			isWelcomeMessage: true,
			botResponse: convoSettings.getWelcomeMessage(widgetSession.productName),
			timestamp: new Date().toISOString(),
			sessionId: widgetSession.id,
			requestId: '',
			page_url: window.location.href,
			userPrompt: '',
			product_name: widgetSession.productName,
			product_sku: widgetSession.productSku,
		})
	}, [JSON.stringify(widgetSession)])


	const { isOpen: isClearModalOpen, onOpen: onClearModalOpen, onClose: onClearModalClose } = useDisclosure()

	const closeClearModel = () => {
		onClearModalClose()

		setTimeout(() => {
			inputRef.current?.focus()
		}, 500)
	}

	const onHandleResetClick = () => {
		reset()
		closeClearModel()
	}

	const hideConvo = () => {
		setAgentStatus({ requestId: agentStatus.requestId, status: '' })
		updateConvo({ requestId: agentStatus.requestId, isHidden: true });
		setEntLoading(false)
	}

	const msgsContainerRef = useRef(null)
	const [windowHeight, setWindowHeight] = useState(window.innerHeight)


	useEffect(() => {
		const handleResize = () => {
			setWindowHeight(window.innerHeight)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const chatHeight = windowHeight - 100

	return (
		<Box
			className="msg__main"
			css={{
				position: 'relative',
				height: `${chatHeight}px`,
				minHeight: '503px',
				overflow: 'auto', // Important to make the content scrollable
				'&::after': {
					content: '""',
					position: 'absolute',
					bottom: 0,
					height: '56px', // Adjust this value for the height of the gradient
					width: '100%',
					background:
						'linear-gradient(to bottom, rgba(39,39,39,0) 0%,rgba(39,39,39,1) 100%)' /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */,
					filter:
						"progid:DXImageTransform.Microsoft.gradient( startColorstr='#00323232', endColorstr='#323232',GradientType=0 )" /* IE6-9 */,
					zIndex: 2, // Ensure the gradient appears above the content
				},
			}}
		>
			<ConvoHeader isLoading={isEntLoading} handleClearClick={onClearModalOpen} />
			{isPrompting && <UserTyping avatar={avatar} bubbleWidth={bubbleWidth}/>}
			{isEntLoading &&
				<div>
					<BotTyping avatar={avatar} bubbleWidth={bubbleWidth}/>
					{agentStatus.status && <div className="pb-[9px]">
						<div className="break-words text-xs pt-1 pb-1 pl-[18px]">
							{agentStatus.status}{' '}
							<span className="text-[#d96c6d] hover:cursor-pointer hover:opacity-80" onClick={hideConvo}>stop</span>
						</div>
					</div>}
				</div>}
			<Box
				name="conv-scroll-container"
				ref={msgsContainerRef}
				className="overflow-y-scroll overflow-x-hidden mb-4"
				css={hoverScrollbarStyle}
			>
				{isTryingToLoadHistory ? <BubbleSkeleton bubbleWidth={bubbleWidth} />
				: <Box className="pb-4">
					{Array.isArray(convos) &&
						convos?.filter(convo => !convo.isHidden).map((convo, index) => {
							return (
								<Fragment key={convo.requestId || index}>
									<Box
										name="convo-message"
										className="message mb-1 mt-1"
										{...(index === 0 ? { ref: latestMessageRef } : {})}
									>
										{convo.customerSupportForm &&
											<div>
												<p className='text-[rgba(255,255,255,0.55)] text-center text-xs font-normal leading-4 pb-2'>
													{convo.customerSupportForm}
												</p>
											</div>
										}
										{(convo.botResponse) &&
											<BotResponse
												convo={convo}
												isCurrent={index === 0}
											/>}
										{convo.userPrompt && <UserPrompt prompt={convo.userPrompt} bubbleWidth={bubbleWidth} />}
									</Box>
								</Fragment>
							)
						})}
				</Box>}
			</Box>
			<ConvoClearModal isOpen={isClearModalOpen} handleModalClose={closeClearModel} onHandleResetClick={onHandleResetClick} />
		</Box>
	)
}

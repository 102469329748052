export const WEB_SEARCH_KEYWORD = 'search the web'
export const WEB_BUTTON_TEXT = 'search the web'
export const FIND_SIMILAR_KEYWORD = 'find products with similar attributes'
export const FIND_SIMILAR_BUTTON_TEXT = 'find similar products'
export const DEFAULT_QUERY_MESSAGE = 'View search results'

export const WELCOME_MESSAGE = "I'm your AI assistant for seamless product discovery. Search, ask questions, and explore detailed product information through this conversational interface."

export const getWelcomeMessage = (productName) => {
    return productName
        ? `${WELCOME_MESSAGE} How can I help you with the ${productName}?`
        : WELCOME_MESSAGE
}

import { useState, useEffect } from "react";
import { useStore } from '../../store/store';
import { FilterCategory } from './FilterCategory';
export const PriceFilters = ({ priceRange, setPriceRange, isFilterOpening, toggleFilter }) => {
    const { searchResults } = useStore(state => ({
        searchResults: state.searchResults,
    }));

    const [priceRanges, setPriceRanges] = useState([]);
    const [selectedOption, setSelectedOption] = useState("custom");

    useEffect(() => {
        if (searchResults.length > 0) {
            const prices = searchResults.map(result => result.price);
            const maxPrice = Math.max(...prices);
            const step = Math.ceil(maxPrice / 5 / 100) * 100; // Round up to the nearest hundred

            const ranges = [];
            for (let i = 0; i < Math.min(5, Math.ceil(maxPrice / 100)); i++) {
                const min = i * step;
                const max = (i + 1) * step;
                ranges.push({ min, max });
            }
            setPriceRanges(ranges);
        }
    }, [searchResults]);

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setSelectedOption(value);

        if (value === "custom") {
            setPriceRange({ min: 0, max: "" });
        } else {
            const selectedRange = priceRanges[value];
            setPriceRange({ min: selectedRange.min, max: selectedRange.max });
        }
    };

    const handleCustomChange = (e) => {
        const { name, value } = e.target;
        setPriceRange((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <div>
            <FilterCategory category="price" isFilterOpening={isFilterOpening} toggleFilter={toggleFilter} />

            {isFilterOpening('price') && (
                <div>
                    {priceRanges.map((range, index) => (
                        <div className="flex items-center mb-2 text-[13px]" key={index}>
                            <input
                                type="radio"
                                id={`range-${index}`}
                                name="price-filters"
                                value={index}
                                checked={selectedOption === index.toString()}
                                onChange={handleRadioChange}
                                className="mr-2 cursor-pointer"
                            />
                            <label htmlFor={`range-${index}`} className="cursor-pointer">
                                {range.min === 0 ? `Under $${range.max}` : `$${range.min} - $${range.max}`}
                            </label>
                        </div>
                    ))}

                    <div className="flex items-center mb-2 text-[13px]">
                        <input
                            type="radio"
                            id="custom"
                            name="price-filters"
                            value="custom"
                            checked={selectedOption === "custom"}
                            onChange={handleRadioChange}
                            className="mr-2 cursor-pointer"
                        />
                        <label htmlFor="custom" className="cursor-pointer">Custom</label>
                    </div>

                    {selectedOption === "custom" && (
                        <div className="flex items-center space-x-2">
                            <input
                                type="number"
                                name="min"
                                placeholder="Min"
                                value={priceRange.min}
                                onChange={handleCustomChange}
                                className="border rounded px-2 py-1 w-[100px]"
                            />
                            <span>to</span>
                            <input
                                type="number"
                                name="max"
                                placeholder="Max"
                                value={priceRange.max}
                                onChange={handleCustomChange}
                                className="border rounded px-2 py-1 w-[100px]"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
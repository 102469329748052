
import React from 'react';
import { Square, CheckSquare2 } from 'lucide-react';
import { FilterCategory } from './FilterCategory';

export const SynthFilters = ({ filters, handleFilterClick, isFilterSelected, isFilterOpening, toggleFilter }) => {

    // Render the UI for refinedSynthFilters
    return (
        <div>
            {filters.map(([key, values], index) => (
                <div key={key + '-' + index}>
                    <div>
                        {/* Filter Category */}
                        <FilterCategory category={key} isFilterOpening={isFilterOpening} toggleFilter={toggleFilter} />
                        {/* Filter Options */}
                        {isFilterOpening(key) && <ul className="text-[13px] text-right">
                            {values.map((value, index) => {
                                return (
                                    <li key={value + '-' + index} className="text-right pr-4 flex items-start cursor-pointer"
                                        onClick={() => handleFilterClick(key, value)}>
                                        <div className="w-[24px]">
                                            {isFilterSelected(key, value)
                                                ? <CheckSquare2 size={'24'} className='pr-2' strokeWidth={1} />
                                                : <Square size={'24'} className='pr-2' strokeWidth={1} />
                                            }
                                        </div>
                                        <span className="mt-[2px] text-left">{value.trim()}</span>
                                    </li>
                                )
                            })}
                        </ul>
                        }
                    </div>
                </div>
            ))}
        </div>
    );
};

{/* <Button type="submit" disabled={isEntLoading} onClick={searchSubmit}>Search</Button> */ }
// const Button = ({ children, ...props }) => {
//     const widgetSession = useStore(state => state.widgetSession)
//     const color = props.disabled ? '#7c7c7c' : widgetSession.appearanceConfig.buttonColor

//     return (
//         <button
//             {...props}
//             className="text-black px-4 py-2 rounded-md text-white font-bold capitalize"
//             style={{ backgroundColor: color }}
//         >
//             {children}
//         </button>
//     )
// }
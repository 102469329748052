import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, } from '@chakra-ui/react'

export const ConvoClearModal = ({ handleModalClose, isOpen, onHandleResetClick }) =>
    <Modal onClose={handleModalClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent maxH="208px" maxW="350px" marginRight={'1.5rem'} marginLeft={'1.5rem'}>
            <ModalHeader css={{ padding: '20px', width: '330px', height: '48px', fontSize: '16px' }}>
                Are you sure you want to clear?
            </ModalHeader>
            <ModalCloseButton
                css={{
                    width: '48px',
                    height: '48px',
                }}
            />
            <ModalBody
                css={{
                    fontSize: '13px',
                    padding: '0px 20px 20px 20px',
                }}
            >
                Clear will delete your conversation and it will no longer be available.
            </ModalBody>
            <ModalFooter alignItems="center" justifyContent="left" css={{ padding: '0px 20px 20px 20px' }}>
                <Button
                    autoFocus
                    _focus={{ boxShadow: 'none' }}
                    css={{
                        width: '120px',
                        height: '36px',
                        backgroundColor: '#4D0782',
                        color: '#ffffff',
                        borderRadius: '32px',
                        fontSize: '12px',
                        ':hover': {
                            backgroundColor: '#7850A0',
                        },
                        ':active': {
                            backgroundColor: '#320A5B',
                        },
                        ':disabled': {
                            backgroundColor: '#DBCDE6',
                        },
                        transition: 'background-color 0.3s ease',
                    }}
                    onClick={onHandleResetClick}
                >
                    Clear
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
import React from 'react';
import { X } from 'lucide-react';

export const ChatOverlayModal = ({ title, children, onClose }) => {
    return (
        <div className="absolute top-0 left-0 w-full h-full z-50 bg-[#272727]">
            <div className="flex justify-between items-center p-[20px]">
                <h3 className='text-white text-lg font-semibold'>{title}</h3>
                <div className='p-[7px] cursor-pointer text-[#FFFFFF]' onClick={onClose}>
                    <X size={'24'} strokeWidth={1.5} color="#ccc" />
                </div>
            </div>
            <hr className="border-[#444] mb-[20px]" />

            <div>
                {children}
            </div>
        </div>
    );
};
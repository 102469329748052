export function toTitleCase(str) {
	// Replace underscores with spaces
	str = str.replace(/_/g, ' ')

	// Convert to title case
	return str.replace(/\w\S*/g, function (word) {
		return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
	})
}

//returns string converted to lowercase with spaces and underscores replaced by hyphens
export function convertToLowercaseWithHyphens(inputStr) {
	return inputStr.toLowerCase().replace(/[\s_]+/g, '-')
}

export function extractPageUrlAndOfferPrice(productData) {
	try {
		const pageUrls = JSON.parse(productData.pageUrl)

		const offerPrices = JSON.parse(productData.price)
		const extractedData = []

		for (let retailer in pageUrls) {
			if (offerPrices[retailer]) {
				extractedData.push({
					retailer,
					pageUrl: pageUrls[retailer],
					offerPrice: offerPrices[retailer],
				})
			}
		}
		return extractedData
	} catch (error) {
		return []
	}
}

export function getRetailerCount(productData) {
	try {
		const offerPrices = JSON.parse(productData.price)

		// Directly get the count of keys (retailers) from the offerPrices object
		return Object.keys(offerPrices).length
	} catch (error) {
		return 0 // return 0 instead of empty array since you are counting
	}
}

export const translateMessagesToHistory = (messages, isPdp = false) => {
	return messages.flatMap((message) => {
		const history = []

		// If 'notMy' field has a message, we add a 'system' message to history
		if (message.notMy) {
			history.push({
				sender: 'system',
				text: message.notMy,
				type: message.type,
				...(isPdp ? { brand: message.brand, mfrNo: message.mfrNo } : {}),
			})
		}

		// If 'my' field has a message, we add a 'user' message to history
		if (message.my) {
			history.push({
				sender: 'user',
				text: message.my,
				type: message.type,
				...(isPdp ? { brand: message.brand, mfrNo: message.mfrNo } : {}),
			})
		}

		return history
	})
}


export function getProductImgUrl(mediaObj) {
	// Flatten all retailer media arrays into a single array
	const allUrls = [].concat(...Object.values(mediaObj))

	// If there's any URL available, return the first one
	if (allUrls.length > 0) {
		return allUrls[0]
	}

	return '/icon/image-coming-soon.png'
}

export function getProductImgUrls(mediaObj) {
	let imagesArr = []

	if (typeof mediaObj === 'object' && mediaObj !== null) {
		for (const retailer in mediaObj) {
			if (Array.isArray(mediaObj[retailer])) {
				// Only include URLs ending with '.jpg', '.JPG', '.jpeg', or '.JPEG'
				const jpgOrJpegImages = mediaObj[retailer].filter(
					(url) => url.toLowerCase().endsWith('.jpg') || url.toLowerCase().endsWith('.jpeg'),
				)
				imagesArr = [...imagesArr, ...jpgOrJpegImages]
			}
		}
	}

	return imagesArr
}

export function extractAllPdfLinks(media) {
	let pdfLinks = []

	for (let key in media) {
		if (media.hasOwnProperty(key)) {
			for (let i = 0; i < media[key].length; i++) {
				if (media[key][i].endsWith('.pdf')) {
					pdfLinks.push(media[key][i])
				}
			}
		}
	}

	return pdfLinks.length > 0 ? pdfLinks : null // Return null if no PDF links are found
}

export function hasNonEmptyReviewValues(reviewsObj) {
	for (let retailer in reviewsObj) {
		// Check if the current retailer has any reviews
		if (reviewsObj[retailer].length > 0) {
			return true // At least one review found
		}
	}
	return false // No reviews found for any retailer
}

export function sortByTimestampAndExtractReviews(obj) {
	let reviews = []

	for (let retailer in obj) {
		for (let review of obj[retailer]) {
			let reviewObj = {
				retailer: retailer,
				timestamp: review.timestamp,
				text: review.text,
				username: review.username,
				title: review.title,
			}

			if (review.location) reviewObj.location = review.location

			reviews.push(reviewObj)
		}
	}

	// Sorting the array based on the timestamp in descending order
	reviews.sort((a, b) => {
		return new Date(b.timestamp) - new Date(a.timestamp)
	})

	return reviews
}

export function getSortedRetailers(offerPricesObj) {
	const level1Retailers = ['Zoro', 'Home Depot']
	// Filter based on level1Retailers and then sort based on prices
	let sortedRetailers = Object.entries(offerPricesObj)
		.filter(([retailer]) => level1Retailers.includes(retailer))
		.sort((a, b) => a[1] - b[1])

	// Map the sorted array to the desired output format
	return sortedRetailers.map(([retailer, price]) => ({
		retailer,
		price,
	}))
}

export function sortRetailersByOfferPrice(data) {
	// Define level 1 retailers. Adjust this list based on your requirements.
	const level1Retailers = ['Zoro', 'Home Depot']

	return data.sort((a, b) => {
		const isAInLevel1 = level1Retailers.includes(a.retailer)
		const isBInLevel1 = level1Retailers.includes(b.retailer)

		// If both are level 1 retailers, sort them by offerPrice.
		if (isAInLevel1 && isBInLevel1) {
			return a.offerPrice - b.offerPrice
		}

		// If neither are level 1, sort them by offerPrice.
		if (!isAInLevel1 && !isBInLevel1) {
			return a.offerPrice - b.offerPrice
		}

		// If only one of them is level 1, that one should come first.
		// No need to check the other condition since it's implicit.
		if (isAInLevel1) return -1
		if (isBInLevel1) return 1
	})
}

export function hasNonEmptySpecValues(attributesObj) {
	return Object.keys(attributesObj).length > 0
}

export function removeQuotes(str) {
	if (!str || str.length < 2) return str // If string is too short or not present, return it unchanged

	const firstChar = str[0]
	const lastChar = str[str.length - 1]

	if ((firstChar === '"' && lastChar === '"') || (firstChar === "'" && lastChar === "'")) {
		return str.slice(1, -1)
	}

	return str
}

export const truncateString = (str, maxLength) => {
	if (str.length > maxLength) {
		return str.slice(0, maxLength - 3) + '...';
	}
	return str;
}

export const convertConvosToChronicle = (convos, prompt) => {
	const newPrompt = {
		sender: 'user',
		text: prompt,
		type: 'ent'
	}

	const cv = !convos ? [] : convos
	const newChronicle = cv.flatMap((convo) => {
		const chronicle = []
		if (convo.botResponse) {
			chronicle.push({
				sender: 'system',
				text: convo.botResponse,
				type: 'ent',
			})
		}
		if (convo.userPrompt) {
			chronicle.push({
				sender: 'user',
				text: convo.userPrompt,
				type: 'ent',
			})
		}
		return chronicle
	})

	return prompt ? [newPrompt, ...newChronicle] : newChronicle
}

export const scrollToTop = (ref) => {
	ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', })
}


export const transformBotMessage = (jsonStr) => {
	let data
	try {
		data = JSON.parse(jsonStr)
	} catch (e) {
		return jsonStr
	}

	// Check if "message", "link" and "text" exist
	if (data.message && data.link && data.link.text) {
		const { url, text } = data.link

		// Split the message into two parts: before and after the "text"
		let [beforeText, afterText] = data.message.split(text)

		// Construct the JSX
		return (
			<div>
				{beforeText}
				<a href={url} target="_blank" rel="noopener noreferrer" style={{ color: '#7EC9E1' }}>
					{text}
				</a>
				{afterText}
			</div>
		)
	}
	return data
}
import { useStore } from '../store/store.js';
import { useEntResults } from './useEntResults.js';
import { utils, sentryUtils } from '../lib/index.js';

export const useSubmitHandler = () => {
    const {
        widgetSession,
        convos,
        prependConvos,
        prevSrpRequestId,
        widgetLog,
        prompt,
        setPrompt,
        setSynthFilters,
        setEntLoading,
        requestSession,
        setIsPrompting,
        lastSearchPrompt,
    } = useStore((state) => ({
        widgetSession: state.widgetSession,
        convos: state.convos,
        prependConvos: state.prependConvos,
        prevSrpRequestId: state.prevSrpRequestId,
        widgetLog: state.widgetLog,
        prompt: state.prompt,
        setPrompt: state.setPrompt,
        setSynthFilters: state.setSynthFilters,
        setEntLoading: state.setEntLoading,
        requestSession: state.requestSession,
        setIsPrompting: state.setIsPrompting,
        lastSearchPrompt: state.lastSearchPrompt,
    }));

    const { fetchEntResults } = useEntResults();

    const handleSubmit = async ({ websiteContext, isFromFilterPanel = false }) => {
        if (!prompt && !isFromFilterPanel) {
            setEntLoading(false);
            return;
        }
        setIsPrompting(false);
        setEntLoading(true);
        setPrompt('');

        if (!isFromFilterPanel) {
            setSynthFilters([]);
        }

        widgetLog('submit');

        try {
            const trimmedPrompt = prompt.trim();
            const chronicle = utils.convertConvosToChronicle(convos, trimmedPrompt);

            prependConvos({
                userPrompt: trimmedPrompt,
            });

            requestSession();

            const data = JSON.stringify({
                user_id: widgetSession.id,
                product_url: widgetSession.url,
                product_name: widgetSession.productName,
                user_input: isFromFilterPanel ? lastSearchPrompt : trimmedPrompt,
                message_history: chronicle,
                client_id: '',
                client_name: 'conversant',
                prev_srp_request_id: prevSrpRequestId,
                website_context: {
                    website_context: websiteContext,
                },
            });
            await fetchEntResults(data);
        } catch (err) {
            setEntLoading(false);
            sentryUtils.captureError(err);
        }
    };

    return { handleSubmit };
}; 
import { ChevronDown, ChevronUp } from 'lucide-react';
export const FilterCategory = ({ category, isFilterOpening, toggleFilter }) => {
    return (
        <div className={`flex items-center justify-between cursor-pointer ${isFilterOpening(category) ? 'opacity-100' : 'opacity-90'} hover:opacity-100`}
            onClick={() => toggleFilter(category)}>
            <div className="text-[16px] font-semibold mt-4 mb-2 capitalize">{category}</div>
            <div>
                {isFilterOpening(category) ? <ChevronUp size={'24'} /> : <ChevronDown size={'24'} />}
            </div>
        </div>
    )
}
import { useState } from 'react';
import { useStore } from '../../store/store';
import { useShallow } from 'zustand/react/shallow';
import { useEffect } from 'react';
import { SynthFilters } from './SynthFilters';
import { PriceFilters } from './PriceFilters';

export const FilterPanel = () => {
    const widgetLinkColor = useStore(state => state.widgetSession.appearanceConfig?.linkColor)
    const { refinedSynthFilters,
        searchResults, setSearchResults,
        selectedFilters, setSelectedFilters,
        resetSelectedFilters
    } = useStore(useShallow(state => ({
        refinedSynthFilters: state.refinedSynthFilters,
        searchResults: state.searchResults,
        setSearchResults: state.setSearchResults,
        selectedFilters: state.selectedFilters,
        setSelectedFilters: state.setSelectedFilters,
        resetSelectedFilters: state.resetSelectedFilters
    })));

    let defaultOpeningFilters = selectedFilters.synth.map(filter => filter.key);
    if (selectedFilters.priceRange.min || selectedFilters.priceRange.max) {
        defaultOpeningFilters.push('price');
    }
    const [openingFilters, setOpeningFilters] = useState(defaultOpeningFilters);


    const isFilterOpening = (key) => openingFilters.includes(key);
    const toggleFilter = (key) => {
        setOpeningFilters(prev => prev.includes(key) ? prev.filter(k => k !== key) : [...prev, key]);
    }

    const isFilterSelected = (key, value) => {
        return selectedFilters.synth.some(filter => filter.key === key && filter.value === value);
    };

    const handleFilterClick = (key, value) => {
        const synth = isFilterSelected(key, value)
            ? selectedFilters.synth.filter(filter => filter.key !== key || filter.value !== value)
            : [...selectedFilters.synth, { key, value }];
        setSelectedFilters({ ...selectedFilters, synth });

    };

    useEffect(() => {
        setSearchResults(searchResults.map(result => {
            const { min, max } = selectedFilters.priceRange;
            const maxPrice = max || Number.MAX_SAFE_INTEGER;

            result.hidden = !(result.price >= min && result.price <= maxPrice);

            if (!result.hidden) {
                if (selectedFilters.synth.length === 0) {
                    result.hidden = false;
                } else {
                    selectedFilters.synth.forEach(filter => {
                        const filterKey = filter.key?.toLowerCase();
                        const filterValue = filter.value?.toLowerCase();
                        const resultValue = result[filterKey]?.toLowerCase();
                        if (resultValue && resultValue.includes(filterValue)) {
                            result.hidden = false;
                        } else {
                            result.hidden = true;
                            return;
                        }
                    });
                }
            }
            return result;
        }));
    }, [selectedFilters]);

    return (
        <div className='px-[20px] text-[#e4e0e0] h-[calc(100vh-78px)] overflow-y-auto pb-10'>
            <div className="flex justify-end">
                <span
                    onClick={resetSelectedFilters}
                    className="cursor-pointer font-semibold text-sm leading-4 text-widgetLinkColor opacity-100 flex items-center gap-1 hover:text-[#CCB4EB] active:text-[#7850A0]"
                    style={{
                        color: widgetLinkColor,
                    }}
                >
                    reset
                </span>
            </div>
            <PriceFilters
                priceRange={selectedFilters.priceRange}
                setPriceRange={(priceRange) => setSelectedFilters({ ...selectedFilters, priceRange })}
                isFilterOpening={isFilterOpening}
                toggleFilter={toggleFilter}
            />
            <SynthFilters
                filters={refinedSynthFilters}
                handleFilterClick={handleFilterClick}
                isFilterSelected={isFilterSelected}
                isFilterOpening={isFilterOpening}
                toggleFilter={toggleFilter}
            />
        </div>
    );
}